/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.hero,#fullpage').fullpage({autoScrolling: false,navigation: false,fitToSection: false});
        //Minimun height is height of Menu
        function setMinHeight() {
          if ($(window).width() > 1100) {
            var menuHeight = $('.brandymenu').outerHeight() + 50; // get .brandymenu height + 50 pixels
            $('.hero .section, #fullpage .section').css('min-height', menuHeight + 'px');
          } else {
            // Optionally, remove the min-height on smaller screens
            $('.hero .section, #fullpage .section').css('min-height', '');
          }
        }
        setMinHeight();
        $(window).resize(function() {
          setMinHeight();
        });

        //Parallax ANimation on Hero Sections
        var animator = new ScrollMagic.Controller({globalSceneOptions : {triggerHook: "onEnter", duration: "160%"}});
        var parallaxParents = document.querySelectorAll('.parallaxParent,.parallax');

        parallaxParents.forEach(function(parent) {
          new ScrollMagic.Scene({
              triggerElement: parent 
            })
            .setTween(parent.querySelector('div'), { y: "-10%", ease: Linear.easeNone })
            //.addIndicators()
            .addTo(animator);
        });

        //Responsive menu
        //Overide collapse behaviour in BS4
        $(".navbar-toggler").on("click",function(e){
          e.preventDefault();//overide BS crap
          $("#mobile-nav").toggleClass("active");
          $(".main").toggleClass("active");
          $("#top-header").toggleClass("active");

        });
 
        // Start midnight on menu
        //$('#top-header').midnight();

        //Animate menu on load
        //$(window).load(function(){
          //$(".nav-primary .flipside").removeClass("flat");
        //});

        //Animate reveal
        $('.revealAnimate').each(function(){
          var controller = new ScrollMagic.Controller();
          var mc = this;
          var tween = new TimelineMax()
              .to(mc, 1, {opacity: 1, yPercent: 0});
          var scene = new ScrollMagic.Scene({triggerElement: mc, triggerHook: 0.8, reverse: false})
              .setTween(tween).addTo(controller);//.addIndicators();
        });


        //Animate main navigation
        // Create a timeline
        //var tl = new TimelineMax();
        //Rotate menu
        // var tween = TweenMax.to(".nav-primary", 0.5, {rotationY: 45});
        // tl.add(tween, 1);//add tween 2 secs into the timeline

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        //$('#fullpage').fullpage({autoScrolling: false,navigation: false,fitToSection: false});

        // var animator = new ScrollMagic.Controller({globalSceneOptions : {triggerHook: "onEnter", duration: "200%"}});
        // new ScrollMagic.Scene({triggerElement: ".parallaxParent "})
        //                 .setTween(".parallaxParent > div", {y: "90%", ease: Linear.easeNone})
        //                 .addIndicators()
        //                 .addTo(animator);




      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        /*
        Beers animation
        */

        //Do not trigger animation on mobiles
        if($(window).width()>767){

            console.log("load animations");

            //var fullScene = new ScrollMagic.Controller({globalSceneOptions : {duration:"200%",triggerHook: 1}});

            //Animate Beer1
            // new ScrollMagic.Scene({triggerElement: "#secc2"}).
            // setTween(TweenMax.fromTo("#secc2 .beer1", 0.5, {
            //       yPercent: -40,
            //       ease: Power0.easeNone
            //   },{
            //       yPercent: 40,
            //       ease: Power0.easeNone
            //   })
            // ).addTo(fullScene);

            //Animate Beer2
            // new ScrollMagic.Scene({triggerElement: "#secc2"}).
            // setTween(TweenMax.fromTo("#secc2 .beer2", 0.5, {
            //       yPercent: -30,
            //       ease: Power0.easeNone
            //   },{
            //       yPercent: 30,
            //       ease: Power0.easeNone
            //   })
            // ).addTo(fullScene);

            

            /*
            Info Animation
            */

            // build tween
            // var tween3 = TweenMax.from(".info", 2, {
            //     yPercent: 50,
            //     alpha:0,
            //     ease: Linear.easeNone
            // });

          //new ScrollMagic.Scene({triggerElement: ".section"})
                          //.setTween(tween1)
                          //.addIndicators()
                          //.addTo(animator2);
        }
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


})(jQuery); // Fully reference jQuery after this point.


// beer page text swap on button click
(function($) { 
  setTimeout(function() {
    if(localStorage.getItem('popState') !== 'shown') {
      $('#mc-signup-popup').modal();
      localStorage.setItem('popState', 'shown');
    }
  }, 5000);
})(jQuery); // Fully reference jQuery after this point.

// Grouped Products Hack to show produt list as buttons
(function($) { 
  $('.grouped-products-list--item').on('click',function(){
    var id = $(this).attr('id').split('-')[1];
    $(this).addClass('selected');
    $(this).siblings().removeClass('selected');
    $('.grouped-product-price').find("#price-"+id).show();
    $('.grouped-product-quantity').find("#quantity-"+id).show();
    $('.grouped-product-price').find("#price-"+id).siblings().hide();
    $('.grouped-product-quantity').find("#quantity-"+id).siblings().hide();
    //Reset all amount inputs to 1 when chaging variant
    $('.grouped-product-quantity').find("#quantity-"+id).find('.input-text').val(1);
    $('.grouped-product-quantity').find("#quantity-"+id).siblings().find('.input-text').val(0);
    
  });
  //.grouped-product-price #price-id
  //.grouped-product-quantity #quantity-id

})(jQuery); 
